import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import CustomButton from "../../Shared/CustomButton";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import CustomTextField from "../../Shared/CustomTextField";
import { IconButton, ModalClose } from "@mui/joy";
import classNames from "classnames";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";
import { Link, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import amaka from "../../Assets/amaka.png";
import brbun from "../../Assets/logo-wide.png";
import loginLogo from "../../Assets/Authentication.gif";
import { useDispatch, useSelector } from "react-redux";
import { setThemeMode } from "../../Redux/Actions/ThemeMode";
import { useQuery, useQueryClient } from "react-query";
import { checkUserReferralFn } from "../../Services/CheckUserReferral";
import { checkReferralFn } from "../../Services/CheckRefferal";
import { useMutation } from "react-query";
import {
  Avatar,
  Badge,
  Divider,
  ListItem,
  Menu,
  MenuItem,
} from "@mui/material";
import { profileFn } from "../../Services/Profile";
import { useState } from "react";
import LogoutDialog from "../../Shared/ConfirmLogout";
import loginIcon from "../../Assets/user.svg";

export default function SignIn() {
  const [phone, setPhone] = React.useState("");
  const [otp, setOTP] = React.useState("");
  const [page, setPage] = React.useState("login");
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isValid, setIsValid] = useState([]);
  const [referralCodeInput, setReferralCodeInput] = useState(null);
  const [valid, setValid] = useState([]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const key = window.location.href?.split("=");
  const referral = key?.[1]?.split("&");
  const referralKey = referral?.[0];

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const isOpen = useSelector((isOpen) => isOpen.themeMode);

  const { data } = useQuery(["profile"], () => profileFn(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const dispatch = useDispatch();
  const handleLogin = (event) => {
    event.preventDefault();
    axiosInstance
      .post(
        API_URLS.login,
        { mobile_number: phone },
        {
          headers: {
            "store-id": 4,
          },
        }
      )
      .then((response) => {
        // alert(response.data.message);
        response.data.message === "OTP has been send to your mobile no.!" ? (
          <>
            `${setPage("otp")} $
            {enqueueSnackbar(response.data.message, { variant: "success" })}`
          </>
        ) : (
          enqueueSnackbar(response.data.message, { variant: "error" })
        );
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };

  const client = useQueryClient();
  const handleOTP = (event) => {
    event.preventDefault();
    axiosInstance
      .post(API_URLS.verifyOTP, {
        mobile_number: phone,
        mobile_otp: otp,
        registration_token: "",
        type: "web",
        device_id: "",
      })
      .then((response) => {
        localStorage.setItem("shubhgatoken", response.data.token);
        localStorage.setItem("mobile", response.data.mobile);
        localStorage.setItem("username", response.data.user_full_name);
        response.data.response_code === 200
          ? dispatch(setThemeMode(false))
          : enqueueSnackbar(response.data.message);
        enqueueSnackbar(response.data.message);
        client.refetchQueries(["myCartList"]);
        client.refetchQueries("wishList");
        client.refetchQueries(["profile"]);
        setOTP(null);
        setPage("login");
        setPhone(null);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!");
      });
  };

  const handleProfile = () => {
    navigate("/user-account");
    handleClose();
  };
  const handleOpen = () => {
    setOpenDialog(true);
    handleClose();
  };
  const emptyFunction = () => {};
  const { mutate } = useMutation(checkUserReferralFn, {
    onSuccess: (res) => {
      setIsValid(res.data);
    },
  });
  useEffect(
    () => {
      phone?.length === 10 ? mutate({ mobile_number: phone }) : emptyFunction();
    },
    // eslint-disable-next-line
    [phone]
  );
  const { mutate: referralCode } = useMutation(checkReferralFn, {
    onSuccess: (res) => {
      setValid(res.data);
    },
  });
  useEffect(
    () => {
      isValid.length !== 0 && valid?.message === "verified"
        ? referralCode({ referral_code: referralCodeInput })
        : emptyFunction();
    },
    // eslint-disable-next-line
    [referralCodeInput]
  );
  useEffect(
    () => {
      referralKey && dispatch(setThemeMode(true));
    },
    // eslint-disable-next-line
    [referralKey]
  );

  return (
    <>
      <Badge
        color="success"
        variant="dot"
        invisible={localStorage.getItem("atoken") ? false : true}
      >
        <img
          alt=""
          src={loginIcon}
          className="h-7"
          onClick={(event) =>
            localStorage.getItem("shubhgatoken")
              ? handleClick(event)
              : dispatch(setThemeMode(true))
          }
        />
      </Badge>

      <Modal
        open={isOpen}
        onClose={() => dispatch(setThemeMode(true))}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box
            className={classNames(
              "absolute flex lg:flex-row flex-col rounded outline-none top-1/2 left-1/2 py-16 lg:py-0 -translate-x-1/2 -translate-y-1/2 md:w-1/2 w-11/12 lg:w-3/5 lg:h-[80%] bg-white  shadow"
            )}
          >
            <ModalClose
              component={IconButton}
              onClick={() => dispatch(setThemeMode(false))}
            />

            <>
              <img
                src={loginLogo}
                alt=""
                className="rounded-l cursor-pointer lg:w-1/2 px-5  hidden lg:block"
              />

              <CustomDiv className="lg:w-1/2 flex flex-col justify-center items-center mx-auto p-5 px-[8%]">
                {page === "login" ? (
                  <>
                    <img src={brbun} alt="" className="my-3 w-1/2" />
                    <Text className="text-2xl font-semibold my-5">Welcome</Text>
                    <Text className="text-sm">
                      Enter your mobile number to start shopping.
                    </Text>

                    {referralKey ? (
                      <Text className="my-2">
                        Your Referral Code :
                        <span className="text-green-500"> {referralKey}</span>
                      </Text>
                    ) : null}
                    <form
                      onSubmit={handleLogin}
                      className="flex flex-col w-full mt-5 gap-10"
                    >
                      <span className="flex flex-col">
                        <Text>Mobile Number</Text>
                        <CustomTextField
                          className="!rounded"
                          type="number"
                          value={phone}
                          onChange={(event) =>
                            event.target.value >= 0 &&
                            setPhone(event.target.value)
                          }
                        />
                      </span>
                      {!referralKey ? (
                        <span className="flex flex-col">
                          {isValid?.message === "new_user_verified" ? (
                            <>
                              <Text classNamemt="">
                                Apply Referral
                                <span className="text-xs">(Optional)</span>
                              </Text>
                              <CustomTextField
                                className="!rounded"
                                type="text"
                                placeholder="Enter referral code"
                                value={referralCodeInput}
                                onChange={(event) =>
                                  setReferralCodeInput(event.target.value)
                                }
                              />
                            </>
                          ) : null}
                        </span>
                      ) : null}
                      <CustomButton
                        disabled={phone?.length === 10 ? false : true}
                        className="!rounded !-mt-10 "
                        type="submit"
                      >
                        Send OTP
                      </CustomButton>
                    </form>
                  </>
                ) : (
                  <>
                    <img src={brbun} alt="" className="my-3 w-1/2" />
                    <Text className="text-2xl font-semibold my-5">
                      Phone Verification
                    </Text>
                    <Text className="text-sm text-center">
                      We have sent a code to your mobile number {phone}
                    </Text>
                    <form
                      onSubmit={handleOTP}
                      className="flex flex-col w-full mt-6 gap-10"
                    >
                      <span className="flex flex-col">
                        <CustomTextField
                          className="!rounded w-full"
                          value={otp}
                          placeholder="Enter OTP"
                          onChange={(event) =>
                            event.target.value >= 0 &&
                            setOTP(event.target.value)
                          }
                        />
                      </span>

                      <CustomButton
                        disabled={otp?.length === 6 ? false : true}
                        className="!rounded"
                        type="submit"
                      >
                        Verify OTP
                      </CustomButton>

                      <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                        <p>Didn't recieve code?</p>
                        <Link
                          className="flex flex-row items-center text-blue-600"
                          rel="noopener noreferrer"
                          onClick={() => setPage("login")}
                        >
                          Resend
                        </Link>
                      </div>
                    </form>
                  </>
                )}
              </CustomDiv>
            </>
          </Box>
        </Fade>
      </Modal>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{ elevation: "2" }}
        className="!top-[18px] lg:!-left-37"
      >
        <ListItem className="!flex gap-2 outline-none">
          <span>
            <Avatar
              src={data?.data?.data?.profile_data?.[0]?.profile_picture}
              alt={data?.data?.data?.profile_data?.[0]?.first_name}
            />
          </span>
          <span className="flex flex-col">
            {data?.data?.data?.profile_data?.[0]?.first_name ? (
              <Text className="whitespace-nowrap">
                {data?.data?.data?.profile_data?.[0]?.first_name}{" "}
                {data?.data?.data?.profile_data?.[0]?.last_name}
              </Text>
            ) : (
              <Text className="whitespace-nowrap">Anonymous</Text>
            )}
            <Text className="text-xs">
              {data?.data?.data?.profile_data?.[0]?.mobile_no}
            </Text>
          </span>
        </ListItem>
        <Divider />
        <MenuItem onClick={() => handleProfile()}>My account</MenuItem>
        <MenuItem onClick={handleOpen}>Logout</MenuItem>
      </Menu>
      <LogoutDialog open={openDialog} setOpen={setOpenDialog} />
    </>
  );
}

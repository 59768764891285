import { CleanHands, UTurnLeft } from "@mui/icons-material";
import { CircularProgress, Divider } from "@mui/joy";
import React, { useEffect, useState } from "react";
import CustomButton from "../../../Shared/CustomButton";
import CustomDiv from "../../../Shared/CustomDiv";
import CustomTextField from "../../../Shared/CustomTextField";
import Text from "../../../Shared/Text";
import Slider from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { API_URLS } from "../../../Config/API_URLS";
import axiosInstance from "../../../Config/axios";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { checkPincodeFn } from "../../../Services/CheckPincode";
import { useDispatch } from "react-redux";
import { setThemeMode } from "../../../Redux/Actions/ThemeMode";
import { HelpOutline } from "@mui/icons-material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  rounded: 2,
};

const ProductPage = () => {
  const { id, vid } = useParams();
  const [thumbnail, setThumbnail] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [detail, setDetail] = useState(false);
  const [tab, setTab] = useState("Description");
  const [variantColor, setVariantColor] = useState("");
  const [variantStorage, setVariantStorage] = useState("");
  const [variant, setVariant] = useState("");
  const [pincode, setPincode] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
  };

  const navigate = useNavigate();

  const handleThaumbnailClick = (src) => {
    setThumbnail(src);
  };

  const productDetailData = () => {
    const reqbody = new FormData();
    reqbody.append("product_id", id);
    reqbody.append("variant_id", vid);
    setIsLoading(true);
    axiosInstance
      .post(
        `api/store/new-product-deatils/?variant_color_value_id=${variantColor}&variant_storage_value_id=${variantStorage}&other_variants_value_id=${variant}`,
        reqbody
      )
      .then((response) => {
        setIsLoading(false);
        setDetail(response.data.data[0].product_deatils[0]);
        setData(response.data.data[0].related_products);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  const dispatch = useDispatch();
  const handleLogin = () => {
    dispatch(setThemeMode(true));
  };
  const client = useQueryClient();
  const addToCart = (event, variantId) => {
    event.stopPropagation();
    const reqBody = new FormData();
    reqBody.append("product_id", variantId);
    reqBody.append("add_quantity", 1);
    axiosInstance
      .post(API_URLS.addToCart, reqBody)
      .then((response) => {
        client.refetchQueries(["myCartList"]);
        enqueueSnackbar(response.data.status);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  useEffect(
    () => {
      productDetailData();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    // eslint-disable-next-line
    [variantColor, variantStorage, id]
  );

  const emptyFunction = () => {};
  const { mutate } = useMutation(checkPincodeFn, {
    onSuccess: (response) => {
      response.data.response_code === 200
        ? setDelivery(
            `Delivery within ${response.data.delivery_time} in ${response.data.city_name}`
          )
        : emptyFunction();
    },
  });

  useEffect(
    () => {
      pincode.length === 6 ? mutate({ zipcode: pincode }) : emptyFunction();
    },
    // eslint-disable-next-line
    [pincode]
  );

  return isLoading ? (
    <>
      <CustomDiv className="flex h-[90vh] justify-center items-center">
        <CircularProgress size="lg" color="primary" />
      </CustomDiv>
    </>
  ) : (
    <CustomDiv className="flex flex-col overflow-x-hidden  shadow px-[2%] lg:px-[8%] py-[4%] w-full">
      <CustomDiv className="flex lg:flex-row flex-col w-full bg-white ">
        <CustomDiv className="border border-1 flex flex-col justify-center lg:w-[40%]">
          <span className="centerdiv flex-col w-full">
            <img
              src={
                thumbnail
                  ? thumbnail
                  : detail?.product_variant_value_list?.[0]
                      ?.varients_multiple_image?.length
                  ? detail?.product_variant_value_list?.[0]
                      ?.varients_multiple_image?.[0]?.variant_image
                  : detail?.product_variant_value_list?.[0]?.variant_image
              }
              alt=""
              className="h-[380px] w-[380px] rounded p-2"
            />
          </span>

          <CustomDiv className="lg:px-8 px-4 pb-3">
            <Slider {...settings} className="home-slider">
              {detail?.product_variant_value_list?.[0]?.varients_multiple_image?.map(
                (item) => {
                  return (
                    <CustomDiv
                      className="cursor-pointer"
                      onClick={() => {
                        handleThaumbnailClick(item.variant_image);
                      }}
                    >
                      <img
                        src={item.variant_image}
                        alt=""
                        className={classNames(
                          "p-1 border border-zinc-200 ",
                          item.variant_image === thumbnail
                            ? "border-zinc-400"
                            : ""
                        )}
                      />
                    </CustomDiv>
                  );
                }
              )}
            </Slider>
          </CustomDiv>
        </CustomDiv>
        {/* <Divider orientation="vertical" /> */}
        <CustomDiv className="flex flex-col min-h-fit lg:w-[60%] justify-center">
          <CustomDiv className="border border-1flex flex-col p-5 w-full">
            <Text className="lg:text-xl text-lg font-semibold capitalize">
              {detail?.product_variant_value_list?.[0]?.variant_name}
            </Text>
            <Text className="pb-2">{detail?.sub_category}</Text>
            <span className="flex items-center gap-2">
              <Text
                className={
                  detail?.product_variant_value_list?.[0]?.stock_sataus ===
                  "In-stock"
                    ? "text-green-600 text-lg font-semibold"
                    : "text-red-600 text-lg font-semibold"
                }
              >
                {detail?.product_variant_value_list?.[0]?.stock_sataus}
              </Text>
              <Text className="text-sm">(Product HSN : {detail?.HSN})</Text>
            </span>

            <Text className="uppercase mb-3">
              {detail?.variant_color?.[0]?.title}
            </Text>
            <CustomDiv className="flex gap-5 overflow-x-auto w-[400px] hide-scroll">
              {detail?.variant_color_value?.map((color) => {
                return (
                  <CustomDiv
                    className={classNames(
                      "flex flex-col cursor-pointer border-[3px] transition-all duration-300 p-2 rounded",
                      detail?.product_variant_value_list?.[0]
                        ?.color_variant_values_id === color.id
                        ? "border-gray-800 rounded-lg"
                        : "border-gray-200 rounded-lg"
                    )}
                    onClick={() =>
                      detail?.product_variant_value_list?.[0]
                        ?.color_variant_values_id === color.id
                        ? null
                        : setVariantColor(color.id)
                    }
                  >
                    <Text>{color.color}</Text>
                  </CustomDiv>
                );
              })}
            </CustomDiv>

            <Text className="uppercase mb-3">
              {detail?.variant_storage?.[0]?.title}
            </Text>
            <CustomDiv className="flex gap-5 overflow-x-auto w-[400px] hide-scroll">
              {detail?.variant_storage_value?.map((storage) => {
                return (
                  <CustomDiv
                    className={classNames(
                      "flex flex-col cursor-pointer border-[3px] transition-all duration-300 p-2 rounded",
                      detail?.product_variant_value_list?.[0]
                        ?.storage_variant_values_id === storage.id
                        ? "border-gray-800 rounded-lg"
                        : "border-gray-200 rounded-lg"
                    )}
                    onClick={() =>
                      detail?.product_variant_value_list?.[0]
                        ?.storage_variant_values_id === storage.id
                        ? null
                        : setVariantStorage(storage.id)
                    }
                  >
                    <Text>{storage.value}</Text>
                  </CustomDiv>
                );
              })}
            </CustomDiv>

            <Text className="uppercase mb-3">
              {detail?.other_variants?.[0]?.title}
            </Text>

            <CustomDiv className="flex gap-5 overflow-x-auto w-[400px] hide-scroll">
              {detail?.other_variants_value?.map((ram) => {
                return (
                  <CustomDiv
                    className={classNames(
                      "flex flex-col cursor-pointer border-[3px] transition-all duration-300 p-2 rounded",
                      detail?.product_variant_value_list?.[0]
                        ?.other_variant_values_id === ram.id
                        ? "border-gray-800 rounded-lg"
                        : "border-gray-200 rounded-lg"
                    )}
                    onClick={() =>
                      detail?.product_variant_value_list?.[0]
                        ?.other_variant_values_id === ram.id
                        ? null
                        : setVariant(ram.id)
                    }
                  >
                    <Text>{ram.value}</Text>
                  </CustomDiv>
                );
              })}
            </CustomDiv>
            <Text className="text-lg">
              MRP
              <span className="font-bold text-xl mx-1">
                ₹{detail?.product_variant_value_list?.[0]?.actual_price}
              </span>
              <span className="line-through mx-1">
                ₹{detail?.product_variant_value_list?.[0]?.variant_price}
              </span>
              <span className="text-green-600 mx-3">
                Save
                <span className="mx-1 ">
                  {detail?.discount_percent
                    ? Number(detail?.discount_percent)
                    : 0}
                  %
                </span>
              </span>
            </Text>
            <Text>Inclusive of all taxes</Text>
            <Text className="w-full bg-zinc-100  p-2 px-4">
              • <span className="text-green-700">FREE Delivery </span> on orders
              by brbun over ₹499
            </Text>
            <CustomDiv className="flex flex-col lg:flex-row items-center lg:px-10 justify-around gap-2 my-2 w-full">
              <CustomButton
                className="!rounded-none !w-full lg:!w-auto !px-10"
                onClick={(event) =>
                  localStorage.getItem("shubhgatoken")
                    ? addToCart(
                        event,
                        detail?.product_variant_value_list?.[0]?.variant_id
                      )
                    : handleLogin()
                }
              >
                Add To Cart
              </CustomButton>

              <Divider orientation="vertical" />
              <span className="flex flex-col gap-4 w-full lg:!w-auto py-6">
                <Text>Delivery Options</Text>
                <CustomTextField
                  type="number"
                  className="!rounded-none !w-full lg:!w-auto"
                  placeholder="Enter Pincode"
                  onChange={(event) => setPincode(event.target.value)}
                />
                <Text>{delivery}</Text>
              </span>
            </CustomDiv>
          </CustomDiv>

          <CustomDiv className="flex flex-col lg:flex-row justify-between gap-5 items-center px-5 w-full bg-gray-200 p-3">
            <span className="flex items-center gap-2">
              <div className="">
                {detail?.is_active_replacement || detail?.is_active_return ? (
                  <Text onClick={handleOpen}>
                    {detail?.is_active_replacement &&
                      detail?.time_period_replacement_policy}
                    <HelpOutline />
                    {detail?.is_active_return &&
                      detail?.time_period_return_replacement_policy}
                  </Text>
                ) : (
                  <Text onClick={handleOpen} className="!text-sm">
                    No Return or Replacement Applicable <HelpOutline />
                  </Text>
                )}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="child-modal-title"
                  aria-describedby="child-modal-description"
                >
                  <Box
                    sx={{ ...style, width: 500 }}
                    className="!border-0 outline-none"
                  >
                    {(detail?.is_active_replacement ||
                      detail?.is_active_return) && (
                      <>
                        <div className="flex justify-between bg-gray-300 p-2">
                          <p>Validity</p>
                          <p>Cover</p>
                          <p>Type Accepted</p>
                        </div>
                        <div className="flex justify-between  p-2">
                          <p className="!w-32">{detail?.validity}</p>
                          <p className="!w-40">{detail?.covers}</p>
                          <p className="!w-32">{detail?.type_accepted}</p>
                        </div>
                      </>
                    )}
                    {detail?.is_active_replacement && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: detail?.replacement_policy,
                        }}
                      ></p>
                    )}
                    {detail?.is_active_return && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            detail?.is_active_return &&
                            detail?.return_and_refund_replacement_policy,
                        }}
                      ></p>
                    )}
                    {!(
                      detail?.is_active_replacement || detail?.is_active_return
                    ) && (
                      <>
                        <p>
                          This product is not eligible for returns.
                          <span className="text-blue-800">Know more.</span>
                        </p>
                      </>
                    )}
                  </Box>
                </Modal>
              </div>
            </span>
            <Text>Sold by : Brbun</Text>
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>

      <Text className="text-xl my-5 font-bold">Product Description</Text>
      <CustomDiv className="bg-white  shadow flex flex-col">
        <CustomDiv className="flex gap-3 px-2">
          {["Description"].map((item) => {
            return (
              <Link onClick={() => setTab(item)} to="#">
                <Text
                  className={classNames(
                    "border-b-2 border-red-500 border-opacity-0 hover:border-opacity-100 p-2",
                    tab === item ? "!border-opacity-100 !text-red-500" : ""
                  )}
                >
                  {item}
                </Text>
              </Link>
            );
          })}
        </CustomDiv>
        <Divider />
        <CustomDiv>
          {tab === "Description" ? (
            <Text className="p-5">{detail?.description}</Text>
          ) : tab === "Ingredients" ? (
            <Text className="p-5">
              Ingredients : Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Dicta, mollitia! Veritatis repellat maiores blanditiis
              corporis, non maxime placeat soluta incidunt a suscipit fuga
              voluptatum ducimus architecto ipsum eveniet error deleniti?
            </Text>
          ) : (
            <Text className="p-5">
              How To Use : Lorem ipsum dolor sit amet consectetur adipisicing
              elit. Dicta, mollitia! Veritatis repellat maiores blanditiis
              corporis, non maxime placeat soluta incidunt a suscipit fuga
              voluptatum ducimus architecto ipsum eveniet error deleniti?
            </Text>
          )}
        </CustomDiv>
      </CustomDiv>
      {/* <Text className="text-xl my-5 font-bold">Product Details</Text>
      <CustomDiv className="bg-white  shadow flex flex-col">
        <Text className="border-b-2 w-fit mx-1 border-red-500 text-red-500 p-2">
          Ratings & Reviews
        </Text>
        <Divider />
        <CustomDiv className="flex gap-2 py-4">
          <span className="flex gap-2 items-start p-5">
            <Text className="text-4xl font-semibold"> 4.5/5</Text>
            <span>
              <Text className="font-bold">Overall Rating</Text>
              <Text>5128 verified ratings</Text>
            </span>
          </span>
          <Divider orientation="vertical" />
          <div className="flex flex-col max-w-xl p-8 shadow-sm rounded-xl lg:p-12 bg-gray-50 text-gray-800">
            <div className="flex flex-col w-full">
              <h2 className="text-3xl font-semibold text-center">
                Customer reviews
              </h2>
              <div className="flex flex-wrap items-center mt-2 mb-1 space-x-2">
                <div className="flex">
                  <button
                    type="button"
                    title="Rate 1 stars"
                    aria-label="Rate 1 stars"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6 text-yellow-500"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    title="Rate 2 stars"
                    aria-label="Rate 2 stars"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6 text-yellow-500"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    title="Rate 3 stars"
                    aria-label="Rate 3 stars"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6 text-yellow-500"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    title="Rate 4 stars"
                    aria-label="Rate 4 stars"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6 text-gray-400"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    title="Rate 5 stars"
                    aria-label="Rate 5 stars"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-6 h-6 text-gray-400"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                    </svg>
                  </button>
                </div>
                <span className="text-gray-600">3 out of 5</span>
              </div>
              <p className="text-sm text-gray-600">861 global ratings</p>
              <div className="flex flex-col mt-4">
                <div className="flex items-center space-x-1">
                  <span className="flex-shrink-0 w-12 text-sm">5 star</span>
                  <div className="flex-1 h-4 overflow-hidden rounded-sm bg-gray-300">
                    <div className="bg-orange-300 h-4 w-5/6"></div>
                  </div>
                  <span className="flex-shrink-0 w-12 text-sm text-right">
                    83%
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="flex-shrink-0 w-12 text-sm">4 star</span>
                  <div className="flex-1 h-4 overflow-hidden rounded-sm bg-gray-300">
                    <div className="bg-orange-300 h-4 w-4/6"></div>
                  </div>
                  <span className="flex-shrink-0 w-12 text-sm text-right">
                    67%
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="flex-shrink-0 w-12 text-sm">3 star</span>
                  <div className="flex-1 h-4 overflow-hidden rounded-sm bg-gray-300">
                    <div className="bg-orange-300 h-4 w-3/6"></div>
                  </div>
                  <span className="flex-shrink-0 w-12 text-sm text-right">
                    50%
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="flex-shrink-0 w-12 text-sm">2 star</span>
                  <div className="flex-1 h-4 overflow-hidden rounded-sm bg-gray-300">
                    <div className="bg-orange-300 h-4 w-2/6"></div>
                  </div>
                  <span className="flex-shrink-0 w-12 text-sm text-right">
                    33%
                  </span>
                </div>
                <div className="flex items-center space-x-1">
                  <span className="flex-shrink-0 w-12 text-sm">1 star</span>
                  <div className="flex-1 h-4 overflow-hidden rounded-sm bg-gray-300">
                    <div className="bg-orange-300 h-4 w-1/6"></div>
                  </div>
                  <span className="flex-shrink-0 w-12 text-sm text-right">
                    17%
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Divider orientation="vertical" />
          {detail?.product_variant_value_list?.[0]?.purchase_status !==
          "No" ? null : (
            <span className="flex flex-col p-2 gap-2">
              <Text>Write a review and win 100 reward points !</Text>
              <WriteReviw
                productId={detail?.product_variant_value_list?.[0]?.variant_id}
              />
            </span>
          )}
        </CustomDiv>
        <Divider />
        <CustomDiv className="p-4">
          <Text className="font-semibold text-lg">Photos From Customers</Text>
          <CustomDiv className="flex gap-3">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => {
              return (
                <img
                  src="https://images-static.nykaa.com/prod-review/1678457592863_1ef01dcd-dbf0-4579-a0ff-1afdb4426cb7_1.jpg?tr=w-145,h-145,cm-pad_resize"
                  alt=""
                  className="p-1 my-2 cursor-pointer h-20 w-20 border border-zinc-200  rounded"
                />
              );
            })}
          </CustomDiv>
        </CustomDiv>
        <Divider className="my-2" />
        <CustomDiv className="p-4">
          <Text className="font-semibold pb-3 text-lg">Most Useful Review</Text>
          {[1, 2, 3, 4, 5].map(() => {
            return (
              <>
                <CustomDiv className="flex items-start p-3 gap-5">
                  <CustomDiv className="flex items-center whitespace-nowrap gap-2">
                    <Avatar src="n" alt="Priyanshi" />
                    <span>
                      <Text>Priyanshi Jain</Text>
                      <Text className="!text-sm">
                        <Verified fontSize="20" color="primary" /> Verified Buyers
                      </Text>
                    </span>
                  </CustomDiv>
                  <CustomDiv className="flex flex-col gap-2">
                    <Chip
                      variant="solid"
                      size="large"
                      color="success"
                      className="!p-1 w-fit !rounded"
                    >
                      <span className="flex items-center">
                        5 <Star fontSize="small" />
                      </span>
                    </Chip>
                    <Text>"Holy grail"</Text>
                    <Text>
                      Leaves no white cast sensitive acne prone skin friendly I
                      sometimes use it without a moisturiser underneath it still
                      performs great Texture is lightweight fluid non
                      greas...Read More
                    </Text>
                    <CustomDiv className="flex gap-3">
                      <img
                        src="https://images-static.nykaa.com/prod-review/1674828768922_4630a968-d8fc-4192-b8dd-5e602fc5b03a_1.jpg?tr=w-150,h-150,pr-true"
                        alt=""
                        className="p-1 border border-zinc-200  h-16 w-16"
                      />
                      <img
                        src="https://images-static.nykaa.com/prod-review/1674828796822_13b580e4-ce39-42ae-834d-d8f8718b702c_1.jpg?tr=w-150,h-150,pr-true"
                        alt=""
                        className="p-1 border border-zinc-200  h-16 w-16"
                      />
                      <img
                        src="https://images-static.nykaa.com/prod-review/1674828816301_8b59ab53-83d2-4e7e-ba45-3ba4c613010c_1.jpg?tr=w-150,h-150,pr-true"
                        alt=""
                        className="p-1 border border-zinc-200  h-16 w-16"
                      />
                    </CustomDiv>
                    <span className="flex gap-5 items-center">
                      <CustomButton
                        variant="outlined"
                        className="!rounded-none"
                      >
                        Helpful
                      </CustomButton>
                      <Text>
                        <span>19</span> people found this helpful
                      </Text>
                    </span>
                  </CustomDiv>
                </CustomDiv>
                <Divider />
              </>
            );
          })}
        </CustomDiv>
        <span className="flex justify-center pb-2 cursor-pointer !text-red-500 items-center">
          <Text>Read more reviews</Text>
          <KeyboardArrowRight />
        </span>
      </CustomDiv> */}

      <CustomDiv className="my-5">
        <Text className="text-xl font-semibold">Related Products</Text>
        <CustomDiv className="flex gap-4 my-5 py-1 w-full overflow-x-auto">
          {data?.map((product) => {
            return (
              <CustomDiv
                className="rounded-md h-fit w-[250px] duration-200 shadow bg-white cursor-pointer "
                onClick={() =>
                  navigate(
                    `/product/${product?.product_id}/${product?.variant_id}`
                  )
                }
              >
                <span className="centerdiv">
                  <img
                    src={product.product_image}
                    alt=""
                    className="h-52 relative"
                  />
                </span>
                <CustomDiv className="flex flex-col justify-between p-4 space-y-2">
                  <CustomDiv className="space-y-2">
                    <Text className="text-lg class-name font-semibold">
                      {product.variant_name}
                    </Text>
                  </CustomDiv>
                  <Text className="text-sm uppercase">
                    {product.main_category}
                  </Text>
                  <CustomDiv className="flex  items-end gap-3 justify-between">
                    <div className="flex">
                      {product.discount_percent !== "0.0" &&
                      product.discount_percent !== "" ? (
                        <Text className="font-bold text-xl items-end">
                          ₹{product.actual_price}
                        </Text>
                      ) : (
                        <Text className="font-bold text-xl items-end">
                          ₹{product.price}
                        </Text>
                      )}
                      {product.price ? (
                        <Text className=" text-sm line-through pt-[5px] mx-2">
                          ₹{product.price}
                        </Text>
                      ) : null}
                    </div>

                    <Text className="text-green-600 text-xs item-right pb-1">
                      {product.discount_percent !== "0.0" &&
                      product.discount_percent !== ""
                        ? `${product.discount_percent}%OFF`
                        : "0%OFF"}
                    </Text>
                  </CustomDiv>
                  <span className="flex gap-4">
                    {/* <IconButton
                      color="primary"
                      variant="solid"
                      // onClick={(event) => {
                      //   product.whishlist_status === "yes" ? (
                      //     <>{` ${enqueueSnackbar(
                      //       "Product is already in wishlist"
                      //     )}
                      //       ${event.stopPropagation()}`}</>
                      //   ) : (
                      //     handleWishList(product?.variant_id, event)
                      //   );
                      // }}
                      className="!rounded"
                    >
                      {product.whishlist_status !== "No" ? (
                        <Favorite />
                      ) : (
                        <FavoriteBorder />
                      )}
                    </IconButton> */}
                    <CustomButton
                      type="button"
                      className="!rounded w-full"
                      onClick={(event) =>
                        localStorage.getItem("shubhgatoken")
                          ? addToCart(event, product.variant_id)
                          : handleLogin()
                      }
                    >
                      Add To Cart
                    </CustomButton>
                  </span>
                </CustomDiv>
              </CustomDiv>
            );
          })}
        </CustomDiv>
      </CustomDiv>
    </CustomDiv>
  );
};

export default ProductPage;

import React, { useState, useEffect } from "react";
import { API_URLS } from "../../Config/API_URLS";
import axiosInstance from "../../Config/axios";
import CustomDiv from "../CustomDiv";
import Text from "../Text";
import { Link } from "react-router-dom";
import shubhga from "../../Assets/amaka.png";
import brandLogo from "../../Assets/logo-wide.png";

const Footer = () => {
  const [store, setStore] = useState([]);

  const Store = () => {
    axiosInstance.get(API_URLS.Store).then((response) => {
      setStore(response.data.data.store_data);
    });
  };

  useEffect(() => {
    Store();
  }, []);
  return (
    <>
      <CustomDiv className="p-6 lg:block hidden border-y border-zinc-200 dark:border-zinc-800 bg-white dark:bg-black">
        <CustomDiv className="px-10 grid grid-cols-5 mx-auto gap-x-3 gap-y-8 ">
          <CustomDiv className="flex flex-col space-y-4">
            <img src={brandLogo} alt="" className="w-36" />
          </CustomDiv>
          <CustomDiv className="flex flex-col font-bold  space-y-4">
            <Link to="/contact-us" rel="noopener noreferrer">
              <Text>Contact Us</Text>
            </Link>
            <Link to="/about-us" rel="noopener noreferrer">
              <Text>About Us</Text>
            </Link>
            <Link to="/return" rel="noopener noreferrer">
              <Text>Return Refund Policy</Text>
            </Link>
          </CustomDiv>
          <CustomDiv className="flex flex-col ">
            <Text className="font-bold ">Quick Links</Text>
            <CustomDiv className="flex gap-5 ">
              <Link to="#" rel="noopener noreferrer">
                <img
                  src="	https://cdn-icons-png.flaticon.com/512/717/717426.png"
                  alt=""
                  className="w-8"
                />
                {store?.store_data?.contact_us?.youtube}
              </Link>

              <Link>
                <img
                  src="	https://cdn-icons-png.flaticon.com/512/61/61109.png"
                  alt=""
                  className="w-6 my-1"
                />
                {store?.store_data?.contact_us?.linkedin}{" "}
              </Link>
            </CustomDiv>
          </CustomDiv>

          <div className="">
            <div className="uppercase ">Social media</div>
            <div className="flex justify-start space-x-3">
              <Link
                rel="noopener noreferrer"
                title="Facebook"
                className="flex items-center p-1"
              >
                <img
                  src="	https://www.iconpacks.net/icons/2/free-facebook-icon-2869-thumb.png"
                  alt=""
                  className="w-6"
                />{" "}
                {store?.store_data?.contact_us?.facebook}
              </Link>
              <Link
                rel="noopener noreferrer"
                title="Twitter"
                className="flex items-center p-1"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733635.png"
                  alt=""
                  className="w-6"
                />
                {store.store_data?.contact_us?.twiter}
              </Link>
              <Link
                rel="noopener noreferrer"
                title="Instagram"
                className="flex items-center p-1"
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/1384/1384031.png"
                  alt=""
                  className="w-6"
                />
                {store.store_data?.contact_us?.insta_id}
              </Link>
            </div>
          </div>
        </CustomDiv>
      </CustomDiv>

      <CustomDiv
        className="flex lg:block hidden flex-col gap-3 
     bg-black py-3"
      >
        <CustomDiv className="flex gap-5 justify-center">
          <Text className="!text-white">
            ©2023 Brbun PVT. LTD.. All Rights Reserved |
            <a href="http://aaratechnologies.in/">
              {" "}
              Designed by Aara Technologies Pvt. Ltd.
            </a>
          </Text>
        </CustomDiv>
      </CustomDiv>
      <CustomDiv className="flex flex-col gap-3 px-3 bg-white py-3">
        <img src={brandLogo} alt="" className="scale-75 block lg:hidden" />
        <CustomDiv className="flex lg:hidden gap-5 justify-center">
          <Link className="" to="/about-us">
            About Us
          </Link>
          <Link className="" to="/contact-us">
            Contact Us
          </Link>
        </CustomDiv>

        <CustomDiv className="flex gap-5 justify-center">
          <Link className="" to="/shipping-policy">
            Privacy Policy
          </Link>

          <Link className="" to="/cancellation-policy">
            Other Policy
          </Link>
          <Link to="/terms-and-policy" rel="noopener noreferrer">
            <Text>Terms & Conditions Policy</Text>
          </Link>
        </CustomDiv>
      </CustomDiv>
      <CustomDiv className="flex lg:hidden flex-col gap-3 bg-black py-3">
        <CustomDiv className="flex gap-5 justify-center">
          <Text className="!text-white text-sm">
            ©2023 CRAZZYHUB RETAIL PVT. LTD.. All Rights Reserved.
          </Text>
        </CustomDiv>
      </CustomDiv>
    </>
  );
};

export default Footer;
